import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {IFleet} from '../../interfaces/fleet.interface';
import {ITeam} from '../../interfaces/team.interface';
import {ILogin} from '../../interfaces/login';
import {IVehicle} from '../../interfaces/vehicle.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IClub} from '../../interfaces/club.interface';
import {IApiKey} from '../../interfaces/api-key.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminRestService {

  constructor(private http: HttpClient,) {
  }


  public getApiKeys() {
    return this.http.get<IApiKey[]>(
      `${environment.apiUrl}/key`
    );
  }

  public createApiKey(apiKey: IApiKey) {
    return this.http.post<IApiKey>(
      `${environment.apiUrl}/key`, apiKey
    );
  }

  public deleteApiKey(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/key/${id}`,
    );
  }

  public getAllClubs() {
    return this.http.get<IClub[]>(
      `${environment.apiUrl}/club`
    );
  }

  public getClubById(id: number) {
    return this.http.get<IClub>(
      `${environment.apiUrl}/club/${id}`
    );
  }

  public updateClub(club: IClub) {
    return this.http.put<IClub>(
      `${environment.apiUrl}/club`, club
    );
  }

  public createClub(club: IClub) {
    return this.http.post<IClub>(
      `${environment.apiUrl}/club`, club
    );
  }

  public getTeamsByClub(id: number): Observable<ITeam[]> {
    return this.http.get<ITeam[]>(
      `${environment.apiUrl}/team?clubId=${id}`
    );
  }


  public getTeamById(id: number): Observable<ITeam> {
    return this.http.get<ITeam>(
      `${environment.apiUrl}/team/${id}`
    );
  }

  public updateTeam(team: ITeam) {
    return this.http.put(
      `${environment.apiUrl}/team`, team
    );
  }

  public createTeam(team: ITeam) {
    return this.http.post<ITeam>(
      `${environment.apiUrl}/team`, team
    );
  }

  public deleteTeam(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/team/${id}`,
    );
  }

  public getRoles(): Observable<any> {
    const roles = [
      'ROLE_ADMIN',
      'ROLE_MANAGER',
      'ROLE_OPERATOR',
      'ROLE_APPROVER',
      'ROLE_AUDITOR'
    ];
    return of(roles);
  }


  public getLoginsByClub(id: number): Observable<ILogin[]> {
    return this.http.get<ILogin[]>(
      `${environment.apiUrl}/login?clubId=${id}`
    );
  }

  public getLoginById(id: number): Observable<ILogin> {
    return this.http.get<ILogin>(
      `${environment.apiUrl}/login/${id}`
    );
  }

  public updateLogin(login: ILogin) {
    return this.http.put<ILogin>(
      `${environment.apiUrl}/login`, login
    );
  }

  public createLogin(login: ILogin) {
    return this.http.post<ILogin>(
      `${environment.apiUrl}/login`, login
    );
  }

  public deleteLogin(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/login/${id}`,
    );
  }


  public getVehiclesByFleet(id: number): Observable<IVehicle[]> {
    return this.http.get<IVehicle[]>(
      `${environment.apiUrl}/vehicle/allByFleet/${id}`
    );
  }

  public getVehicleById(id: number): Observable<IVehicle> {
    return this.http.get<IVehicle>(
      `${environment.apiUrl}/vehicle/${id}`
    );
  }

  public updateVehicle(vehicle: IVehicle) {
    return this.http.put<IVehicle>(
      `${environment.apiUrl}/vehicle`, vehicle
    );
  }

  public createVehicle(vehicle: IVehicle) {
    return this.http.post<IFleet>(
      `${environment.apiUrl}/vehicle`, vehicle
    );
  }

  public deleteVehicle(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/vehicle/${id}`,
    );
  }


  public getFleetsByClub(id: number): Observable<IFleet[]> {
    return this.http.get<IFleet[]>(
      `${environment.apiUrl}/fleet/?clubId=${id}`
    );
  }

  public updateFleet(fleet: IFleet) {
    return this.http.put<IFleet>(
      `${environment.apiUrl}/fleet`, fleet
    );
  }

  public createFleet(fleet: IFleet) {
    return this.http.post<IFleet>(
      `${environment.apiUrl}/fleet`, fleet
    );
  }

  public deleteFleet(id: number) {
    return this.http.delete(
      `${environment.apiUrl}/fleet/${id}`
    );
  }

  public getFleetById(id: number): Observable<IFleet> {
    return this.http.get<IFleet>(
      `${environment.apiUrl}/fleet/${id}`
    );
  }


}
