import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {JobRestService} from './rest/job-rest.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {IPagination} from '../../administration/interfaces/pagination';

@Injectable({
  providedIn: 'root'
})

export class JobService {
  public showErrorPageJobs: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public jobs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public jobTypeAndState: BehaviorSubject<IJobTypeAndState> = new BehaviorSubject<IJobTypeAndState>({
    type: 'AUTHORISATION',
    status: 'ALL'
  });
  public myFleets: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private jobRestService: JobRestService) {
  }

  public getMyFleets(): void {
    this.jobRestService.getMyFleets().subscribe(response => {
        response ? this.myFleets.next(response) : this.myFleets.next([]);
        this.showErrorPageJobs.next('CONTENT_JOBS');
      },
      (): void => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageJobs.next('ERROR_JOBS');
      });
  }

  public getJobs(iPage: IPagination): void {
    this.showErrorPageJobs.next('LOADING');
    delete iPage.count;
    delete iPage.maxPages;
    /*
    der Status "ALL" existiert nicht in der DB bzw bei einem JOB,
    deswegen wird kein Status abgefragt, somit bekommnt man vom BE alle Jobs zurück.
    Jedoch bleibt das 'ALL' in der GUI weiter vorhanden
     */
    if (iPage.status === 'ALL') {
      iPage.status = null;
    }
    if (iPage.fleetID) {
      this.jobRestService.getJobsRest(iPage).subscribe(response => {
          response ? this.jobs.next(response) : this.jobs.next([]);
          this.showErrorPageJobs.next('CONTENT_JOBS');
        },
        (): void => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
          this.showErrorPageJobs.next('ERROR_JOBS');
        });
    }
  }
}


export interface IJobTypeAndState {
  type: string;
  status: string;
}
