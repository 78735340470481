<ng-container *ngIf="setting.settingType === 'ACCEPT_UNKNOWN_VEHICLES' || setting.settingType === 'ALLOW_JOB_CANCELATION'
 || setting.settingType=== 'MAIL_NOTIFICATION' || setting.settingType=== 'PUSH_STATUS_CHANGE'">
  <input [checked]="setting.value" [value]="setting.value" (change)="checkBoxChanged()" type="checkbox"
         class="checkbox checkbox-primary" id="{{setting.settingType}}"><label
  for="{{setting.settingType}}">{{'SETTINGS.' + label | translate}}</label>
</ng-container>

<ng-container *ngIf="setting.settingType === 'ADDITIONAL_NOTIFICATION_MAILS'">
  <div class="form-group row no-gutters">
    <label for="{{setting.settingType}}" class="col-2 col-form-label">{{'SETTINGS.' + label | translate}}</label>
    <div class="col-10">
      <input name="{{setting.settingType}}" type="email" class="form-control" [(ngModel)]="setting.value"
             multiple pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$">
    </div>
  </div>
</ng-container>
