import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ILogin} from '../../interfaces/login';
import {ActivatedRoute} from '@angular/router';
import {AdminRestService} from '../../services/rest/admin-rest.service';
import {AdminService} from '../../services/admin.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-edit-login',
  templateUrl: './edit-login.component.html'
})
export class EditLoginComponent implements OnInit, OnDestroy {
  public Subscriptions: Subscription[] = [];
  public url: string;
  public currentId: number;
  public login = {} as ILogin;
  public requestState: string;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService,
              private adminService: AdminService,
              private adminRestService: AdminRestService) {
  }

  ngOnInit(): void {
    this.url = this.route['_routerState'].snapshot.url;
    this.Subscriptions.push(this.route.params.subscribe(params => {
      this.currentId = params.id;
      if (this.currentId > 0) {
        this.adminRestService.getLoginById(this.currentId).subscribe((data) => {
          this.login = data;
        }, () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        });
      }
    }));
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }


  public keyDownFunction(event: KeyboardEvent, valid: boolean) {
    if (valid && event.keyCode === 13) {
      this.submitData();
    }
  }


  public onSubmit(event: Event, valid: boolean) {
    event.preventDefault();
    if (valid) {
      this.submitData();
    }
  }

  public submitData() {
    this.requestState = 'PENDING';
    setTimeout(() => {
      let request;
      if (this.currentId) {
        request = this.adminRestService.updateLogin(this.login as ILogin);
      } else {
        this.login.clubId = Number(this.adminService.clubId.value);
        request = this.adminRestService.createLogin(this.login as ILogin)
      }
      request.pipe(
        finalize(() => this.requestState = undefined)).subscribe(() => {
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESSFUL'));
      }, error => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR'));
      });
    }, 250);
  }
}
