import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IPagination} from '../../../administration/interfaces/pagination';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class JobRestService {

  constructor(private http: HttpClient) {
  }

  public getMyFleets() {
    return this.http.get<any>(
      `${environment.apiUrl}/fleet/myFleets`
    );
  }

  public getJobsRest(iPage: IPagination){
    return this.http.post<any>(
      `${environment.apiUrl}/job`, iPage
    );
  }

  public getJobById(id: number) {
    return this.http.get<any>(
      `${environment.apiUrl}/job/${id}`
    );
  }

  public updateJob(job: any) {
    return this.http.put<any>(
      `${environment.apiUrl}/job`, job
    );
  }

  public assignJob(jobId: number, username: string) {
    return this.http.put<any>(
      `${environment.apiUrl}/job/assign/${jobId}?username=${username}`, username
    );
  }

  public getAssignableUsers(jobId: number) {
    return this.http.get<any>(
      `${environment.apiUrl}/job/getAssignableUsers/${jobId}`
    );
  }

  public getSCVDownload(params: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiUrl}/job/getAllJobsCsv`, params, { responseType: 'arraybuffer' as 'json' });
  }
}
